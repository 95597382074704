import { Menu } from './menu.model';

export const verticalMenuItems = [ 
    new Menu (1, 'Tableau de bord', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'Produit', '/produit', null, 'view_module', null, false, 0), 
    new Menu (3, 'Categorie', '/categorie', null, 'view_module', null, false, 0), 
    new Menu (4, 'Zone', '/zone', null, 'view_module', null, false, 0),
    new Menu (5, 'Stock', '/stock', null, 'view_module', null, false, 0), 
    new Menu (6, 'Chauffeur', '/chauffeur', null, 'view_module', null, false, 0), 
    new Menu (6, 'Statut', '/allStatut', null, 'view_module', null, false, 0), 
    new Menu (7, 'Localisation', null, null, 'view_module', null, true, 0), 
    new Menu (7, 'Pays', "/allPays", null, 'view_module', null, false, 7), 
    new Menu (7, 'Region', "/allRegion", null, 'view_module', null, false, 7), 
    new Menu (7, 'Commune', "/allCommune", null, 'view_module', null, false, 7), 
    
]

export const horizontalMenuItems = [ 
    new Menu (1, 'Dashboard', '/', null, 'dashboard', null, false, 0),
    new Menu (2, 'Produit', '/produit', null, 'view_module', null, false, 0), 
    new Menu (3, 'Categorie', '/categorie', null, 'view_module', null, false, 0), 
    new Menu (4, 'Zone', '/zone', null, 'view_module', null, false, 0), 
    new Menu (6, 'Chauffeur', '/chauffeur', null, 'view_module', null, false, 0), 


   
]