import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommandeService } from '../services/commande.service';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';
import { UserService } from '../services/user.service';
import { ZoneService } from '../services/zone.service';

@Component({
  selector: 'app-add-stock',
  templateUrl: './add-stock.component.html',
  styleUrls: ['./add-stock.component.scss']
})
export class AddStockComponent implements OnInit {

 
  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<AddStockComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private distrubuteur:UserService,private produitService: ProduitService,private commandeService:CommandeService,private fb: FormBuilder, private zoneService: ZoneService, private _snackBar: SnackBarService) { }
    etatPadding: boolean = true;
    produits: any[] = [];
    users: any[] = [];
    zone: any;
    zoneForm = this.fb.group({
    fournisseur_id: ['', []],
    produit_id: ['', []],
    quantite: ['', []],
    prix: ['', []],
   
  });

  ngOnInit(): void {
  
    this.getProduits();
 
    this.distributeurs();
  }

  onSubmit(){
    this.etatPadding = false;
    this.zoneForm.value.fournisseur_id= this.userService.getUserDetails()._id
    this.commandeService.addStock(this.zoneForm.value).subscribe(res => {
      console.log(this.zoneForm.value)
      this._snackBar.openSnackBar("Plage Ajouter!!", "Fermer");
     console.log("zone",this.zoneForm.value);
      this.etatPadding = true;
      
      this.dialogRef.close();
    })
   
  
  }
  distributeurs()
  {
   this.distrubuteur.allUsersF().subscribe(res=>
     {
       this.users=res;
       console.log("Distributeur",this.users);
     })
 }

  get prix(){
    return this.zoneForm.get('prix');
  }
  get quantite(){
    return this.zoneForm.get('quantite');
  }

  get total(){
    return this.zoneForm.get('total');
  }
  get categorie_id(){
    return this.zoneForm.get('id_zone');
  }
  get produit_id(){
    return this.zoneForm.get('produit_id');
  }

  getProduits(){
    this.produitService.getProduits(this.userService.getUserDetails()._id).subscribe(res => {
     this.produits = res;
     console.log("produit",this.produits);
    });
  }


}
