import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroupDirective, NgForm, UntypedFormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { ProduitService } from '../services/produit.service';
import { SnackBarService } from '../services/snack-bar.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-add-categorie',
  templateUrl: './add-categorie.component.html',
  styleUrls: ['./add-categorie.component.scss']
})
export class AddCategorieComponent implements OnInit {
  selectedFiles?: FileList;
  selectedFileNames: string[] = [];

  progressInfos: any[] = [];
  message: string[] = [];

  previews: string[] = [];
  imageInfos?: Observable<any>;
  settings: Settings;
  granite = true;
  fileData: File = null;
  bam: boolean = false;
  etatPadding: boolean = true;
  constructor(private _snckBar: SnackBarService,public dialogRef: MatDialogRef<AddCategorieComponent>,public userService:UserService,public appSettings:AppSettings,private fb: FormBuilder,private produitService: ProduitService) {
    this.settings = this.appSettings.settings;     
  }
  ngOnInit(): void {
   
  }
  categorieForm =  this.fb.group({
    nom: ['',  {
      validators: [
      Validators.required,
    ]},],
    image: [''],
    distributeur_id: [''],
   
   
  });
  onSubmit(){
    this.etatPadding = false;
    const formData = new FormData();
    formData.append('file', this.image.value);
    if(this.bam){
      this.produitService.upload(formData).subscribe(res => {
        this.image.setValue(res.path);
        this.distributeur_id.setValue(this.userService.getUserDetails()._id);
        this.produitService.addCategorie(this.categorieForm.value).subscribe(res => {
         
          console.log("groupe",this.categorieForm.value);
          this.dialogRef.close();
          this._snckBar.openSnackBar("categorie Ajouter!!", "Fermer");
          this.etatPadding = true;

        });
      })
    }else{
      this.distributeur_id.setValue(this.userService.getUserDetails()._id);

      this.produitService.addCategorie(this.categorieForm.value).subscribe(res => {
        this._snckBar.openSnackBar("categorie Ajouter!!", "Fermer");
        this.dialogRef.close();
        this.etatPadding = true;

      });
    }
    
  }
  close(): void {
    this.dialogRef.close();
  }
  get image(){
    return this.categorieForm.get('image');
  }
  get distributeur_id(){
    return this.categorieForm.get('distributeur_id');
  }
  //Input with a custom ErrorStateMatcher

 

  //Input with a clear button

 
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    if (fileInput.target.files.length > 0) {
      const file = fileInput.target.files[0];
      this.categorieForm.get('image').setValue(file);
      this.bam = true;
    }
  }
}



/** Error when invalid control is dirty, touched, or submitted. */

