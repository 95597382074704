import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription, timer } from 'rxjs';
import { AddPlageComponent } from 'src/app/add-plage/add-plage.component';
import { AddZoneComponent } from 'src/app/add-zone/add-zone.component';
import { DeleteZoneComponent } from 'src/app/delete-zone/delete-zone.component';
import { ZoneService } from 'src/app/services/zone.service';
import { UpdateZoneComponent } from 'src/app/update-zone/update-zone.component';
import { DeletePlageComponent } from '../delete-plage/delete-plage.component';

@Component({
  selector: 'app-view-plage',
  templateUrl: './view-plage.component.html',
  styleUrls: ['./view-plage.component.scss']
})
export class ViewPlageComponent implements OnInit {
  public isReady= false
  public count= 0
  subscription: Subscription;
  displayedColumns: string[] = ['produit', 'minimum', 'maximum','nom','total','action'];
    constructor( @Inject(MAT_DIALOG_DATA) public data: any,private zoneService:ZoneService,private dialog: MatDialog){}
 

  dataSource = new MatTableDataSource<PeriodicElement>(null);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ngAfterViewInit() {
    

  }
  ngOnInit(): void {
    this.subscription =  timer(0, 3000).subscribe(res => {
      this.getAllOrders();
      this.count++
    });
  
  }


  
  getAllOrders() {
    this.zoneService.viewPlage(this.data.id).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource <PeriodicElement> (data[0].plage); //pass the array you want in the table
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      console.log(this.dataSource.data)
      if(this.count==1){
         this.isReady= true
      }
      return data[0].plage
    })}


    addPlage(id){
      this.dialog.open(AddPlageComponent, {
        width: '400px',
        height: '500px',
        data: {id: id}
      })
    }
   
    updateZone(id){
      this.dialog.open(UpdateZoneComponent, {
        width: '400px',
        height: '380px',
        data: {id: id}
      })
    }
    deletePlage(id,id_zone){
      this.dialog.open(DeletePlageComponent, {
       
        data: {id: id,id_zone:id_zone}
      })
    }

}

export interface PeriodicElement {
  produit: string;
  minimum: string;
  maximum: string;
  nom: string;
  total: string;
  action: string;
 
 
 
}