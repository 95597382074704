import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../backend';
import { Injectable } from '@angular/core';
export interface Element {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const data: Element[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
  {position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na'},
  {position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg'},
  {position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al'},
  {position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si'},
  {position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P'},
  {position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S'},
  {position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl'},
  {position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar'},
  {position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K'},
  {position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca'},
];

@Injectable({
  providedIn: 'root'
})
export class ZoneService {
  api = `${baseUrl}zones`;

  constructor(private http: HttpClient) { }

  public addZone(zone: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addZone`, zone).pipe(
      retry(3)
    );
  }
  public addPlage(zones: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addPlage`, zones).pipe(
      retry(3)
    );
  }
  public getCamionByChauffeur(id): Observable<any>{
    return this.http.get<any>(`${this.api}/getCamionByChauffeur/${id}`).pipe(
      retry(3)
    );
  }
  public getAllCommuneByRegion(id): Observable<any>{
    return this.http.get<any>(`${this.api}/getAllCommuneByRegion/${id}`).pipe(
      retry(3)
    );
  }
  public getAllCommuneByZone(id): Observable<any>{
    return this.http.get<any>(`${this.api}/getAllCommuneByZone/${id}`).pipe(
      retry(3)
    );
  }
  public addChauffeur(zones: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addChauffeur`, zones).pipe(
      retry(3)
    );
  }
  public addStock(stock: any): Observable<any>{
    return this.http.post<any>(`${this.api}/addStock`, stock).pipe(
      retry(3)
    );
  }
  
  public countChauffeur(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/countChauffeur`).pipe(
      retry(3)
    );
  }

  public allChauffeur(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/allChauffeur`).pipe(
      retry(3)
    );
  }
  public deletePlage(id,id_zone): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/deletePlage/${id}/${id_zone}`).pipe(
      retry(3)
    );
  }
  public viewPlage(id: any): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/viewPlage/${id}`).pipe(
      retry(3)
    );
  }
  public viewTransport(id: any): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/viewTransport/${id}`).pipe(
      retry(3)
    );
  }
  public getZones(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getZones`).pipe(
      retry(3)
    );
  }
  
  public countZone(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/countZone`).pipe(
      retry(3)
    );
  }

  public getZoness(): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getZoness`).pipe(
      retry(3)
    );
  }

  public getPaginatedZones(page: any): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getPaginatedZones/${page}`).pipe(
      retry(3)
    );
  }

  public updateChauffeur(id: any, zone): Observable<any>{
    return this.http.put<any>(`${this.api}/updateChauffeur/${id}`, zone).pipe(
      retry(3)
    );
  }
  public addTransport(id: any, transport): Observable<any>{
    return this.http.post<any>(`${this.api}/addTransport/${id}`, transport).pipe(
      retry(3)
    );
  }
  public deleteChauffeur(id): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/deleteChauffeur/${id}`).pipe(
      retry(3)
    );
  }
  public updateZone(id: any, zone): Observable<any>{
    return this.http.put<any>(`${this.api}/updateZone/${id}`, zone).pipe(
      retry(3)
    );
  }
  public getZone(id): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getZone/${id}`).pipe(
      retry(3)
    );
  }
  public getChauffeur(id): Observable<any[]>{
    return this.http.get<any[]>(`${this.api}/getChauffeur/${id}`).pipe(
      retry(3)
    );
  }
  public deleteZone(id): Observable<any>{
    return this.http.get<any>(`${this.api}/deleteZone/${id}`).pipe(
      retry(3)
    );
  }
  public deleteTransport(id): Observable<any>{
    return this.http.get<any>(`${this.api}/deleteTransport/${id}`).pipe(
      retry(3)
    );
  }
}
